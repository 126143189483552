<template>
  <UserIndex
      :create-text="`New ${$tc('learner',1)}`"
      :can-create="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
      :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete learners')
    "
      :default-role="'learner'"
      :default-role-filter="'learner'"
      :show-roles="false"
      :roles-filter="false"
      :type="`${$tc('Learner',1)}`"
      :route="'learners'"
      :tabs="[{ title: `${$tc('learner',1)} List` }]"
      :tabEntity="$tc('Learner',1)"
      :header-text="$tc('Learner',2)"
  ></UserIndex>
</template>
<script>
import UserIndex from "@/components/users/UserIndex";

export default {
  name: "Guardians.index",
  components: { UserIndex },
};
</script>
